<template>
  <div id="map" />
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { onMounted, watch, reactive, toRaw } from "vue";
import { calculaPorcentaje, semaforoMapa } from "@/utils";
import MapaApi from "@/api/mapa";

export default {
  props: {
    mapa: {
      type: Object
    }
  },
  setup(props) {
    /* eslint-disable  vue/no-setup-props-destructure*/
    const mapa = props.mapa;

    const coloresSemaforo = reactive({
      POCO_DENOMINADOR: "5",
      PORCENTAJE_BAJO: "10",
      PORCENTAJE_MEDIO: "20",
      PORCENTAJE_ALTO: "30"
    });

    const coloresSemaforoMapa = reactive({
      RANGO_SINDATO: "5",
      RANGO_BAJO: "10",
      RANGO_MEDIO: "20",
      RANGO_ALTO: "30"
    });

    watch(
      () => props.mapa,
      (val) => {
        fetchData(val);
      }
    );

    const fetchData = async (mapa) => {
      var response = await MapaApi.getSemaforoMapaPublicv2(mapa.indicador);

      if (response.data.length) {
        coloresSemaforo.POCO_DENOMINADOR = response.data.find(
          (p) => p.clave === "RANGO_SINDATO"
        ).valor;
        coloresSemaforo.PORCENTAJE_BAJO = response.data.find((p) => p.clave === "RANGO_BAJO").valor;
        coloresSemaforo.PORCENTAJE_MEDIO = response.data.find(
          (p) => p.clave === "RANGO_MEDIO"
        ).valor;
        coloresSemaforo.PORCENTAJE_ALTO = response.data.find((p) => p.clave === "RANGO_ALTO").valor;

        coloresSemaforoMapa.RANGO_SINDATO = response.data.find(
          (p) => p.clave === "RANGO_SINDATO"
        ).valor;
        coloresSemaforoMapa.RANGO_BAJO = response.data.find((p) => p.clave === "RANGO_BAJO").valor;
        coloresSemaforoMapa.RANGO_MEDIO = response.data.find(
          (p) => p.clave === "RANGO_MEDIO"
        ).valor;
        coloresSemaforoMapa.RANGO_ALTO = response.data.find((p) => p.clave === "RANGO_ALTO").valor;
      }

      mapboxgl.accessToken = process.env.VUE_APP_MAPBOXTOKEN;
      const map = new mapboxgl.Map({
        container: "map",
        center: [mapa.lat, mapa.lng],
        zoom: mapa.zoom,
        style: "mapbox://styles/mapbox/light-v9"
      });

      if (mapa.idubigeo == "SIVINUR") {
        map.on("load", () => {
          var distritos = mapa.data_nombres;
          var numeradores = mapa.total_num_distrito;
          var denominadores = mapa.total_den_distrito;

          for (let index = 0; index < distritos.length; index++) {
            const calcular = calculaPorcentaje(numeradores[index], denominadores[index]);

            const colorGenerated = semaforoMapa(coloresSemaforoMapa, numeradores[index], calcular);

            map.addSource(distritos[index], {
              type: "geojson",
              data: process.env.VUE_APP_BASE_API + "/api/PruebaName/V1/" + distritos[index]
            });

            map.addLayer({
              id: distritos[index],
              type: "fill",
              source: distritos[index],
              paint: {
                "fill-color": colorGenerated,
                "fill-opacity": 0.8,
                "fill-outline-color": "rgba(20, 100, 25, 1)"
              }
            });

            map.on("click", distritos[index], function (e) {
              new mapboxgl.Popup()
                .setLngLat(e.lngLat)
                .setHTML(
                  `<h2 style="color:Teal;font-weight:bold">${distritos[index]}</h2><p>Porcentaje: <strong>${calcular} %</strong></p><p>Numerador: <strong>${numeradores[index]}</strong></p><p>Denominador: <strong>${denominadores[index]}</strong></p>`
                )
                .addTo(map);
            });
          }
        });
      } else {
        if (mapa.idubigeo == "") {
          if (mapa.data_nombres.length == 1) {
            map.on("load", () => {
              var distritos = mapa.distrito;
              var numeradores = mapa.total_num_distrito;
              var denominadores = mapa.total_den_distrito;

              for (let index = 0; index < distritos.length; index++) {
                const calcular = calculaPorcentaje(numeradores[index], denominadores[index]);

                const colorGenerated = semaforoMapa(
                  coloresSemaforoMapa,
                  numeradores[index],
                  calcular
                );

                map.addSource(distritos[index], {
                  type: "geojson",
                  data: process.env.VUE_APP_BASE_API + "/api/PruebaName/V1/" + distritos[index]
                });

                map.addLayer({
                  id: distritos[index],
                  type: "fill",
                  source: distritos[index],
                  paint: {
                    "fill-color": colorGenerated,
                    "fill-opacity": 0.8,
                    "fill-outline-color": "rgba(20, 100, 25, 1)"
                  }
                });

                map.on("click", distritos[index], function (e) {
                  new mapboxgl.Popup()
                    .setLngLat(e.lngLat)
                    .setHTML(
                      `<h2 style="color:Teal;font-weight:bold">${distritos[index]}</h2><p>Porcentaje: <strong>${calcular} %</strong></p><p>Numerador: <strong>${numeradores[index]}</strong></p><p>Denominador: <strong>${denominadores[index]}</strong></p>`
                    )
                    .addTo(map);
                });
              }
            });
          } else {
            map.on("load", () => {
              map.addSource("CANDARAVE", {
                type: "geojson",
                data: process.env.VUE_APP_BASE_API + "/api/Prueba/V1/candarave"
              });

              map.addSource("TACNA", {
                type: "geojson",
                data: process.env.VUE_APP_BASE_API + "/api/Prueba/V1/tacna"
              });

              map.addSource("JORGE BASADRE", {
                type: "geojson",
                data: process.env.VUE_APP_BASE_API + "/api/Prueba/V1/jorge_basadre"
              });

              map.addSource("TARATA", {
                type: "geojson",
                data: process.env.VUE_APP_BASE_API + "/api/Prueba/V1/tarata"
              });

              var mapas = toRaw(mapa.provincias) || [];

              // mapa.denominador
              for (let index = 0; index < mapas.length; index++) {
                const calcular = calculaPorcentaje(mapa.numerador[index], mapa.denominador[index]);

                const colorGenerated = semaforoMapa(
                  coloresSemaforoMapa,
                  mapa.denominador[index],
                  calcular
                );

                map.addLayer({
                  id: mapa.data_nombres[index],
                  type: "fill",
                  source: mapa.data_nombres[index],
                  paint: {
                    "fill-color": colorGenerated,
                    "fill-opacity": 0.8,
                    "fill-outline-color": "rgba(20, 100, 25, 1)"
                  }
                });

                map.on("click", mapa.data_nombres[index], function (e) {
                  new mapboxgl.Popup()
                    .setLngLat(e.lngLat)
                    .setHTML(
                      `<h2 style="color:Teal;font-weight:bold">${mapa.data_nombres[index]}</h2><p>Porcentaje: <strong>${mapa.provincias[index]} %</strong></p><p>Numerador: <strong>${mapa.numerador[index]}</strong></p><p>Denominador: <strong>${mapa.denominador[index]}</strong></p>`
                    )
                    .addTo(map);
                });
              }
            });
          }
        } else {
          map.on("load", () => {
            const calcular = calculaPorcentaje(
              mapa.total_numerador_distrito,
              mapa.total_denominador_distrito
            );

            const colorGenerated = semaforoMapa(
              coloresSemaforoMapa,
              mapa.total_denominador_distrito,
              calcular
            );

            map.addSource("distrito", {
              type: "geojson",
              data: process.env.VUE_APP_BASE_API + "/api/Prueba/V1/" + mapa.idubigeo
            });

            map.addLayer({
              id: "distrito-fill",
              type: "fill",
              source: "distrito",
              paint: {
                "fill-color": colorGenerated,
                "fill-opacity": 0.8,
                "fill-outline-color": "rgba(20, 100, 25, 1)"
              }
            });

            map.on("click", "distrito-fill", function (e) {
              new mapboxgl.Popup()
                .setLngLat(e.lngLat)
                .setHTML(
                  `<h2 style="color:Teal;font-weight:bold">${mapa.abreviatura}</h2><p>Porcentaje: <strong>${calcular} %</strong></p><p>Numerador: <strong>${mapa.total_numerador_distrito}</strong></p><p>Denominador: <strong>${mapa.total_denominador_distrito}</strong></p>`
                )
                .addTo(map);
            });
          });
        }
      }
    };

    onMounted(() => {
      fetchData(mapa);
    });

    return {
      fetchData
    };
  }
};
</script>

<style scoped>
#map {
  height: 425px;
}
</style>
