<template>
  <section class="p-6">
    <LayoutPublicHeader :isDark="true" />
    <main class="text-center mt-10">
      <a-button
        @click="goBack"
        class="hidden md:block float-left md:ml-3 border-0 hover:bg-gray-400 bg-gray-500 text-white"
        size="large"
      >
        <template #icon> <ArrowLeftOutlined class="text-white" /></template>
        Atrás
      </a-button>
    </main>
    <div class="md:grid md:grid-cols-2">
      <div class="p-5 bg-gray-200 rounded-md md:col-span-2 md:mx-auto">
        <IndicadorFiltros @changedistrito="changedistrito" @changePeriodo="changePeriodo" />
      </div>
    </div>

    <TituloPorcentajeIndicador
      :isLoading="isLoading"
      :porcentaje="porcentaje"
      :abreviatura="indicadorData.abreviatura"
      :denominacion="indicadorData.denominacion"
      :numerador="numerador"
      :titulonumerado="indicadorData.titulonumerado"
      :denominador="denominador"
      :titulodenominado="indicadorData.titulodenominado"
      :porcentajeColor="porcentajeColor"
      :sigla="indicadorData.sigla"
    />

    <fieldset
      class="w-full flex flex-col lg:flex-row gap-6 mb-4 border-2"
      v-if="paquetesIntegrados.length"
    >
      <legend class="text-xs font-bold">Actividades:</legend>

      <div
        v-for="paquete in paquetesIntegrados"
        :key="paquete.id"
        class="flex gap-5 items-center p-3 bg-white rounded-xl border-4 border-gray-200"
      >
        <div class="text-lg font-bold">{{ paquete.valor }}</div>
        <p>{{ paquete.texto }}</p>
      </div>
    </fieldset>

    <PaquetePreventivo
      v-if="esIndicadorMC.ok"
      :escalaColores="escalaColoresPaquete"
      :optionsGrafico="optionsGrafico"
      :optionsGraficoValor="optionsGraficoValor"
      :title="esIndicadorMC.title"
    />

    <div class="flex flex-col gap-8 lg:flex-row w-full">
      <div class="lg:w-1/2 gap-2 mb-4 md:gap-3 lg:gap-5">
        <div class="text-center mt-2">
          <h1 class="p-2 bg-white font-semibold border border-gray-400">
            Indicador por distritos ({{ data.length }})
          </h1>
          <span><CaretDownOutlined style="color: #fff" /></span>
        </div>
        <a-table
          :columns="columnsIndicadorDistrito"
          :data-source="data"
          size="small"
          :pagination="{ size: 'large' }"
          rowKey="distrito"
          :rowClassName="
            (_record, index) =>
              index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
          "
        >
          <template #rangepages="{ record }">
            <span> {{ data.indexOf(record) + 1 }} </span>
          </template>

          <template #porcentaje="{ record, text: porcentaje }">
            <div class="flex gap-4 justify-between items-center">
              <div class="w-3 h-3 rounded-full" :style="{ background: record.porcentajeHex }"></div>
              <p class="font-bold">{{ porcentaje }} % &nbsp;</p>
            </div>
          </template>
          <template #numeradordenominador="{ record }">
            <p class="text-sm font-semibold text-right">
              {{ record.total_numerador }} /
              {{ record.total_denominador }}
            </p>
          </template>
        </a-table>
        <FooterFuente :fuente="fechaIngresoTabla" />
      </div>
      <div class="lg:w-1/2 gap-2 mb-4 md:gap-3">
        <div class="text-center mt-2">
          <h1 class="p-2 bg-white font-semibold border border-gray-400">
            Mapa de avance por provincia
          </h1>
          <span><CaretDownOutlined style="color: #fff" /></span>
        </div>
        <div>
          <MapMapbox :mapa="mapbox" />
        </div>

        <h4 class="text-base my-2">Leyenda:</h4>
        <div class="flex gap-3 flex-wrap">
          <div v-for="escala in escalaColores" :key="escala.texto" class="flex gap-1 items-center">
            <div
              class="w-4 h-4 rounded-full border border-white"
              :style="{ backgroundColor: escala.color }"
            ></div>
            {{ escala.texto }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap md:flex-nowrap gap-5 w-full my-5">
      <a-card size="small" class="w-full md:w-1/2 relative">
        <template #title>
          <h1 class="text-center font-semibold text-lg">AVANCE</h1>
        </template>
        <v-chart :option="grafica.gauge.options" style="width: 100%; height: 250px" />

        <div class="md:flex md:justify-center md:gap-2 absolute bottom-8">
          <div
            v-for="escala in escalaColores"
            :key="escala.texto"
            class="flex gap-2 items-center text-xxs"
          >
            <div class="w-3 h-3 rounded-full" :style="{ backgroundColor: escala.color }"></div>
            {{ escala.texto }}
          </div>
        </div>
      </a-card>

      <a-card size="small" class="w-full md:w-1/2">
        <template #title>
          <h1 class="text-center font-semibold text-lg">AVANCE POR MESES</h1>
        </template>
        <v-chart :option="grafica.line.options" style="width: 100%; height: 250px" />
      </a-card>
    </div>

    <div class="w-full flex flex-wrap md:flex-nowrap gap-4">
      <div class="flex flex-col w-full">
        <a-card size="small">
          <template #title>
            <h1 class="text-center font-semibold text-lg">AVANCE POR PROVINCIA</h1>
          </template>
          <v-chart :option="graphics.provincia" style="width: 100%; height: 300px" />
        </a-card>
      </div>

      <div class="flex flex-col w-full">
        <a-card size="small">
          <template #title>
            <h1 class="text-center font-semibold text-lg">AVANCE POR DISTRITO</h1>
          </template>
          <v-chart :option="grafica.barRainfall.options" :style="{ height: height + 'px' }" />
        </a-card>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, ref, toRaw, reactive, onUnmounted } from "vue";
import { useStore } from "vuex";
import { ArrowLeftOutlined, CaretDownOutlined } from "@ant-design/icons-vue";
import { columnsIndicadorDistrito } from "./utilsIndicador";
import { optionsBar, provinciaGraphic, optionsGauge, optionsLine } from "./utilsIndicadorGrafica";
import {
  calculaPorcentaje,
  generaLeyendaSemaforo,
  semaforoByPorcentajeIndicador,
  semaforoMapa
} from "@/utils";
import {
  generaGraficosLine,
  optionGraphic,
  generaLeyendaPaquete,
  generaGraficosLineNinio,
  optionGraphicNinio,
  generaGraficosLineValor,
  optionGraphicValor,
  generaGraficosLineNinioValor,
  optionGraphicNinioValor,
  generaLeyendaPaqueteNinio
} from "@/components/utilsPaquetePreventivo";
import LayoutPublicHeader from "@/layout/LayoutPublic/LayoutPublicHeader.vue";
import TituloPorcentajeIndicador from "@/components/TituloPorcentajeIndicador.vue";
import IndicadorFiltros from "./IndicadorFiltros";
import MapMapbox from "@/components/MapMapbox.vue";
import ApiIndicador from "@/api/indicador";
import MapaApi from "@/api/mapa";
import { formateaIdsPeriodoHuman } from "@/utils";
import router from "@/router";
import FooterFuente from "@/components/FooterFuente.vue";
import PaquetePreventivo from "@/components/PaquetePreventivo.vue";
import EstadisticoApi from "@/api/estadistico";
import { useRoute } from "vue-router";

export default {
  components: {
    LayoutPublicHeader,
    IndicadorFiltros,
    CaretDownOutlined,
    MapMapbox,
    FooterFuente,
    TituloPorcentajeIndicador,
    PaquetePreventivo,
    // icons
    ArrowLeftOutlined
  },
  setup() {
    const idindicador = ref("");

    const route = useRoute();
    const store = useStore();
    const data = ref([]);
    const abreviatura = ref("");
    const denominacion = ref("");
    const fichatecnica = ref("");
    const titulonumerado = ref("");
    const titulodenominado = ref("");
    const provincias = ref([]);
    const data_denominador = ref([]);
    const data_numerador = ref([]);
    const data_nombres = ref([]);
    const tituloreporte = ref("");
    const sigla = ref("");
    const calculo = ref(0.0);
    const numerador = ref(0.0);
    const denominador = ref(0.0);
    const height = ref("900");
    const escalaColores = ref([]);
    const fechaIngresoTabla = ref("");
    const paquetesIntegrados = ref([]);
    const escalaColoresPaquete = ref([]);
    const optionsGrafico = ref(null);
    const optionsGraficoValor = ref(null);

    const esIndicadorMC = reactive({
      ok: false,
      tipo: "",
      title: ""
    });

    const coloresSemaforo = reactive({
      RANGO_SINDATO: "RANGO_SINDATO",
      RANGO_BAJO: "RANGO_BAJO",
      RANGO_MEDIO: "RANGO_MEDIO",
      RANGO_ALTO: "RANGO_ALTO"
    });

    const coloresSemaforoMapa = reactive({
      DENOMINADOR: "5",
      RANGO_SINDATO: "5",
      RANGO_BAJO: "10",
      RANGO_MEDIO: "20",
      RANGO_ALTO: "30"
    });

    const indicadorData = reactive({
      denominacion: "",
      fichatecnica: "",
      titulonumerado: "",
      titulodenominado: "",
      tituloreporte: "",
      abreviatura: "",
      query: "",
      sigla: ""
    });

    const porcentajeColor = ref("");
    idindicador.value = route.params?.indicador;

    const porcentaje = ref("");
    const isLoading = ref(false);

    const mapbox = ref({
      abreviatura: "",
      idcoordenada: "",
      idubigeo: "",
      denominacion: "",
      data_nombres: [],
      lat: "-70.2646366",
      lng: "-17.651106",
      zoom: 7,
      indicador: idindicador.value
    });

    const graphics = reactive({
      provincia: null
    });

    const grafica = reactive({
      pie: {
        options: null
      },
      barRainfall: {
        options: null,
        title: ""
      },
      barLabel: {
        options: null
      },
      gauge: {
        options: null
      },
      line: {
        options: null
      }
    });

    const fetchData = () => {
      ApiIndicador.getInfo(toRaw(idindicador.value)).then((response) => {
        indicadorData.denominacion = response.data[0].denominacion;
        indicadorData.titulonumerado = response.data[0].titulonumerado;
        indicadorData.titulodenominado = response.data[0].titulodenominado;
        indicadorData.abreviatura = response.data[0].abreviatura;
        indicadorData.sigla = response.data[0].sigla;

        sigla.value = response.data[0].sigla;
        fichatecnica.value = response.data[0].fichatecnica;
        tituloreporte.value = response.data[0].tituloreporte;
        abreviatura.value = response.data[0].abreviatura;

        changePeriodo();
      });
    };

    const fetchPaqueteIntegrado = (abreviatura) => {
      if (abreviatura === "MC-01.01" || abreviatura === "MC-02.01") {
        const periodo_ini = store.getters["mapa/periodo_ini"];
        const periodo_fin = store.getters["mapa/periodo_fin"];
        const provincia = store.getters["mapa/provincia"];
        const distrito = store.getters["mapa/distrito"];
        const quintil = store.getters["mapa/quintil"];

        if (abreviatura === "MC-01.01") {
          esIndicadorMC.ok = true;
          esIndicadorMC.tipo = "gestante";
          esIndicadorMC.title = "Proporción de gestantes con paquete preventivo completo";

          const payload = {
            provincia,
            distrito,
            quintil,
            periodo_ini,
            periodo_fin
          };

          EstadisticoApi.paquetePreventivoMC1(payload)
            .then((response) => {
              const valoresGraficas = generaGraficosLine(response.data);
              optionsGrafico.value = optionGraphic("", valoresGraficas);

              const valoresGraficasValor = generaGraficosLineValor(response.data);
              optionsGraficoValor.value = optionGraphicValor("", valoresGraficasValor);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              escalaColoresPaquete.value = generaLeyendaPaquete();
            });

          ApiIndicador.getPaqueteIndicadorRangoMC1(
            periodo_ini,
            periodo_fin,
            distrito,
            provincia,
            quintil
          )
            .then((response) => {
              const temporal = [
                {
                  id: "examen",
                  valor: response.data[0].examen,
                  texto: "Gestantes con 04 exámenes auxilares en el 1er trimestre"
                },
                {
                  id: "prenatal",
                  valor: response.data[0].prenatal,
                  texto: "Gestantes con 04 o más atenciones prenatales"
                },
                {
                  id: "suplemento",
                  valor: response.data[0].suplemento,
                  texto: "Gestantes con suplemento (sulfato ferroso mas ácido fólico)"
                }
              ];

              paquetesIntegrados.value = temporal;
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (abreviatura === "MC-02.01") {
          esIndicadorMC.ok = true;
          esIndicadorMC.tipo = "ninio";
          esIndicadorMC.title =
            "Proporción de niños menores de 1año con paquete preventivo completo - Tacna";

          const payload = {
            provincia,
            distrito,
            quintil,
            periodo_ini,
            periodo_fin
          };

          EstadisticoApi.paquetePreventivoMC2(payload)
            .then((response) => {
              const valoresGraficas = generaGraficosLineNinio(response.data);
              optionsGrafico.value = optionGraphicNinio("", valoresGraficas);

              const valoresGraficasValor = generaGraficosLineNinioValor(response.data);
              optionsGraficoValor.value = optionGraphicNinioValor("", valoresGraficasValor);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              escalaColoresPaquete.value = generaLeyendaPaqueteNinio();
            });

          ApiIndicador.getPaqueteIndicadorRangoMC2(
            periodo_ini,
            periodo_fin,
            distrito,
            provincia,
            quintil
          )
            .then((response) => {
              const temporal = [
                {
                  id: "numerocred",
                  valor: response.data[0].numerocred,
                  texto:
                    "Niñas y niños con control de crecimiento y desarrollo (CRED) según su edad"
                },
                {
                  id: "numerovacuna",
                  valor: response.data[0].numerovacuna,
                  texto:
                    "Niñas y niños con vacunas básicas completas (antipolio, pentavalente, neumococo y rotavirus)."
                },
                {
                  id: "numerosuplemento",
                  valor: response.data[0].numerosuplemento,
                  texto:
                    "Niñas y niños con suplemento completo para su edad (hierro en gotas, jarabe o micronutrientes)"
                },
                {
                  id: "numerodosaje",
                  valor: response.data[0].numerodosaje,
                  texto:
                    "Niñas y niños con dosaje de hemoglobina según su edad (realizado entre los 6 a 8 meses de edad)"
                }
              ];

              paquetesIntegrados.value = temporal;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        esIndicadorMC.ok = false;
        esIndicadorMC.tipo = "";
        esIndicadorMC.title = "";
      }
    };

    // --------- visualizar por provincia ---------
    const getAllByProvincia = async () => {
      const periodo_ini = store.getters["mapa/periodo_ini"];
      const periodo_fin = store.getters["mapa/periodo_fin"];

      const distrito = store.getters["mapa/distrito"];
      const provincia = store.getters["mapa/provincia"];
      const quintil = store.getters["mapa/quintil"];

      // PERIODO

      var res_periodoini = await ApiIndicador.getPeriodoBySigla(periodo_ini, idindicador.value);
      var res_periodofin = await ApiIndicador.getPeriodoBySigla(periodo_fin, idindicador.value);

      var periodoini = res_periodoini.data[0]["idperiodoevaluacion"];
      var periodofin = res_periodofin.data[0]["idperiodoevaluacion"];

      // END PERIODO

      // ---- datos provincia ----
      ApiIndicador.getAllByProvincia(
        toRaw(sigla.value),
        provincia,
        periodo_ini,
        periodo_fin,
        quintil,
        periodoini,
        periodofin
      )
        .then((response) => {
          const pieData = [];

          var data = [];
          var denominadores = [];
          var numeradores = [];
          var nombres = [];

          response.data.forEach((element) => {
            pieData.push({
              value: calculaPorcentaje(
                element.total_numerador_provincia,
                element.total_denominador_provincia
              ),
              name: element.provincia
            });

            data.push(
              calculaPorcentaje(
                element.total_numerador_provincia,
                element.total_denominador_provincia
              )
            );
            denominadores.push(element.total_denominador_provincia);
            numeradores.push(element.total_numerador_provincia);
            nombres.push(element.provincia);
          });

          data_nombres.value = nombres;
          provincias.value = data;
          data_denominador.value = denominadores;
          data_numerador.value = numeradores;
          changedistrito();

          graphics.provincia = provinciaGraphic(pieData);
        })
        .catch((err) => console.log(err));

      // ----- datos indicador indicador -----

      // PERIODO

      var res_periodoini = await ApiIndicador.getPeriodoBySigla(periodo_ini, idindicador.value);
      var res_periodofin = await ApiIndicador.getPeriodoBySigla(periodo_fin, idindicador.value);

      var periodoini = res_periodoini.data[0]["idperiodoevaluacion"];
      var periodofin = res_periodofin.data[0]["idperiodoevaluacion"];

      // END PERIODO

      ApiIndicador.getIndicadorIndicador(
        distrito,
        toRaw(sigla.value),
        provincia,
        periodo_ini,
        periodo_fin,
        quintil,
        periodoini,
        periodofin
      ).then((response) => {
        const conPorcentajes = response.data.map((p) => {
          const porcentaje = calculaPorcentaje(p.total_numerador, p.total_denominador);

          const porcentajeHex = semaforoMapa(
            coloresSemaforoMapa,
            p.total_denominador_establecimiento,
            porcentaje
          );

          return {
            ...p,
            porcentaje,
            porcentajeHex
          };
        });

        data.value = conPorcentajes;

        if (data.value.length > 0) {
          porcentaje.value = calculaPorcentaje(
            response.data[response.data.length - 1].total_numerador_distrito,
            response.data[response.data.length - 1].total_denominador_distrito
          );

          porcentajeColor.value = semaforoByPorcentajeIndicador(
            "39.9",
            "59.9",
            "79.9",
            porcentaje.value
          );

          var con_numerador = 0;
          var con_denominador = 0;

          response.data.forEach((x) => {
            con_numerador += parseInt(x["total_numerador"]);
            con_denominador += parseInt(x["total_denominador"]);
          });

          numerador.value = con_numerador;
          denominador.value = con_denominador;
        } else {
          porcentaje.value = 0;
          numerador.value = 0;
          denominador.value = 0;
        }

        grafica.gauge.options = optionsGauge(toRaw(porcentaje.value), toRaw(porcentajeColor.value));
      });
    };

    // ------ escala colores mapa ------
    const getEscalaColoresMapa = async () => {
      var res = await MapaApi.getSemaforoMapaPublicv2(idindicador.value);

      coloresSemaforo.RANGO_SINDATO = res.data.find((p) => p.clave === "RANGO_SINDATO").descripcion;
      coloresSemaforo.RANGO_BAJO = res.data.find((p) => p.clave === "RANGO_BAJO").descripcion;
      coloresSemaforo.RANGO_MEDIO = res.data.find((p) => p.clave === "RANGO_MEDIO").descripcion;
      coloresSemaforo.RANGO_ALTO = res.data.find((p) => p.clave === "RANGO_ALTO").descripcion;

      coloresSemaforoMapa.RANGO_SINDATO = res.data.find((p) => p.clave === "RANGO_SINDATO").valor;
      coloresSemaforoMapa.RANGO_BAJO = res.data.find((p) => p.clave === "RANGO_BAJO").valor;
      coloresSemaforoMapa.RANGO_MEDIO = res.data.find((p) => p.clave === "RANGO_MEDIO").valor;
      coloresSemaforoMapa.RANGO_ALTO = res.data.find((p) => p.clave === "RANGO_ALTO").valor;

      escalaColores.value = generaLeyendaSemaforo(toRaw(coloresSemaforo));
    };

    onMounted(() => {
      store.dispatch("mapa/setPeriodo", "");
      store.dispatch("mapa/setUbigeo", "");

      if (idindicador.value == 14 || idindicador.value == 15) {
        store.dispatch("mapa/setQuintil", 1);
      }

      getEscalaColoresMapa();
      fetchData();
      fetchFooterFuente();
    });

    onUnmounted(() => {
      store.dispatch("mapa/cleanFilters");
    });

    const changePeriodo = async () => {
      const periodo_ini = store.getters["mapa/periodo_ini"];
      const periodo_fin = store.getters["mapa/periodo_fin"];

      const distrito = store.getters["mapa/distrito"];
      const provincia = store.getters["mapa/provincia"];
      const quintil = store.getters["mapa/quintil"];

      // PERIODO

      var res_periodoini = await ApiIndicador.getPeriodoBySigla(periodo_ini, idindicador.value);
      var res_periodofin = await ApiIndicador.getPeriodoBySigla(periodo_fin, idindicador.value);

      var periodoini = res_periodoini.data[0]["idperiodoevaluacion"];
      var periodofin = res_periodofin.data[0]["idperiodoevaluacion"];

      // END PERIODO

      LocalgetAllByPeriodo();

      ApiIndicador.getIndicadorIndicador(
        distrito,
        toRaw(sigla.value),
        provincia,
        periodo_ini,
        periodo_fin,
        quintil,
        periodoini,
        periodofin
      )
        .then((response) => {
          const conPorcentajes = response.data.map((p) => {
            const porcentaje = calculaPorcentaje(p.total_numerador, p.total_denominador);

            const porcentajeHex = semaforoMapa(
              coloresSemaforoMapa,
              p.total_denominador_establecimiento,
              porcentaje
            );

            return {
              ...p,
              porcentaje,
              porcentajeHex
            };
          });

          data.value = conPorcentajes;

          if (data.value.length > 0) {
            porcentaje.value = calculaPorcentaje(
              response.data[response.data.length - 1].total_numerador_distrito,
              response.data[response.data.length - 1].total_denominador_distrito
            );

            porcentajeColor.value = semaforoByPorcentajeIndicador(
              "39.9",
              "59.9",
              "79.9",
              porcentaje.value
            );

            var con_numerador = 0;
            var con_denominador = 0;

            response.data.forEach((x) => {
              con_numerador += parseInt(x["total_numerador"]);
              con_denominador += parseInt(x["total_denominador"]);
            });

            numerador.value = con_numerador;
            denominador.value = con_denominador;
          } else {
            porcentaje.value = 0;
            numerador.value = 0;
            denominador.value = 0;
          }

          getAllByProvincia();
        })
        .catch((err) => console.log(err))
        .finally(() => {
          fetchPaqueteIntegrado(indicadorData.abreviatura);
        });
    };

    const changedistrito = async () => {
      const ubigeo = store.getters["mapa/ubigeo"];
      const provincia = store.getters["mapa/provincia"];

      if (provincia != "" && ubigeo == "") {
        const periodo_ini = store.getters["mapa/periodo_ini"];
        const periodo_fin = store.getters["mapa/periodo_fin"];
        const quintil = store.getters["mapa/quintil"];
        const distrito = "";

        // PERIODO

        var res_periodoini = await ApiIndicador.getPeriodoBySigla(periodo_ini, idindicador.value);
        var res_periodofin = await ApiIndicador.getPeriodoBySigla(periodo_fin, idindicador.value);

        var periodoini = res_periodoini.data[0]["idperiodoevaluacion"];
        var periodofin = res_periodofin.data[0]["idperiodoevaluacion"];

        // END PERIODO

        ApiIndicador.getIndicadorIndicador(
          distrito,
          toRaw(sigla.value),
          provincia,
          periodo_ini,
          periodo_fin,
          quintil,
          periodoini,
          periodofin
        )
          .then((response) => {
            var distritos = response.data;
            var data_nombres = [];
            var total_numerador_distrito = [];
            var total_denominador_distrito = [];

            distritos.forEach((element) => {
              data_nombres.push(element.distrito);

              total_numerador_distrito.push(element.total_numerador);
              total_denominador_distrito.push(element.total_denominador);
            });

            mapbox.value = {
              abreviatura: "",
              idcoordenada: "",
              idubigeo: "SIVINUR",
              provincias: [],
              denominador: 0,
              numerador: 0,
              data_nombres: data_nombres,
              total_num_distrito: total_numerador_distrito,
              total_den_distrito: total_denominador_distrito,
              distrito: distrito,
              denominacion: "",
              lat: "-70.2646366",
              lng: "-17.651106",
              zoom: 7.4,
              indicador: 1
            };
          })
          .catch((err) => console.log(err));
      } else {
        if (ubigeo == "") {
          const periodo_ini = store.getters["mapa/periodo_ini"];
          const periodo_fin = store.getters["mapa/periodo_fin"];
          const quintil = store.getters["mapa/quintil"];
          const distrito = "";

          LocalgetAllByPeriodo();

          // PERIODO

          var res_periodoini = await ApiIndicador.getPeriodoBySigla(periodo_ini, idindicador.value);
          var res_periodofin = await ApiIndicador.getPeriodoBySigla(periodo_fin, idindicador.value);

          var periodoini = res_periodoini.data[0]["idperiodoevaluacion"];
          var periodofin = res_periodofin.data[0]["idperiodoevaluacion"];

          // END PERIODO

          ApiIndicador.getIndicadorIndicador(
            distrito,
            toRaw(sigla.value),
            provincia,
            periodo_ini,
            periodo_fin,
            quintil,
            periodoini,
            periodofin
          )
            .then((response) => {
              const distrito = [];
              const total_denominador_distrito = [];
              const total_numerador_distrito = [];
              const porcentaje_distrito = [];

              const conPorcentajes = response.data.map((p) => {
                const porcentaje = calculaPorcentaje(p.total_numerador, p.total_denominador);

                const porcentajeHex = semaforoMapa(
                  coloresSemaforoMapa,
                  p.total_denominador,
                  porcentaje
                );

                return {
                  ...p,
                  porcentaje,
                  porcentajeHex
                };
              });

              data.value = conPorcentajes;

              porcentaje.value = calculaPorcentaje(
                response.data[response.data.length - 1]?.total_numerador_distrito,
                response.data[response.data.length - 1]?.total_denominador_distrito
              );

              porcentajeColor.value = semaforoByPorcentajeIndicador(
                coloresSemaforoMapa.RANGO_SINDATO,
                coloresSemaforoMapa.RANGO_BAJO,
                coloresSemaforoMapa.RANGO_MEDIO,
                porcentaje.value
              );

              var con_numerador = 0;
              var con_denominador = 0;

              response.data.forEach((x) => {
                con_numerador += parseInt(x["total_numerador"]);
                con_denominador += parseInt(x["total_denominador"]);
              });

              numerador.value = con_numerador;
              denominador.value = con_denominador;

              response.data.forEach((element) => {
                distrito.push(element.distrito);
                total_numerador_distrito.push(element.total_numerador);
                total_denominador_distrito.push(element.total_denominador);

                porcentaje_distrito.push(
                  calculaPorcentaje(element.total_numerador, element.total_denominador)
                );
              });

              grafica.barRainfall.options = optionsBar(
                distrito,
                total_numerador_distrito,
                total_denominador_distrito,
                porcentaje_distrito
              );

              grafica.gauge.options = optionsGauge(
                toRaw(porcentaje.value),
                toRaw(porcentajeColor.value)
              );

              mapbox.value = {
                abreviatura: "",
                idcoordenada: "",
                idubigeo: "",
                provincias: provincias.value,
                denominador: data_denominador.value,
                numerador: data_numerador.value,
                data_nombres: data_nombres.value,
                total_num_distrito: total_numerador_distrito,
                total_den_distrito: total_denominador_distrito,
                distrito: distrito,
                denominacion: "",
                lat: "-70.2646366",
                lng: "-17.651106",
                zoom: 7.4,
                indicador: idindicador.value
              };
            })
            .catch((err) => console.log(err));
        } else {
          MapaApi.getInfo(ubigeo)
            .then(async (response) => {
              const denominacionParse = response.data[0].denominacion;
              const denominacion = JSON.parse(denominacionParse);
              const abreviatura = response.data[0].abreviatura;
              const idcoordenada = response.data[0].idcoordenada;
              const idubigeo = response.data[0].idubigeo;
              const quintil = store.getters["mapa/quintil"];

              store.dispatch("mapa/setDistrito", abreviatura);
              const periodo_ini = store.getters["mapa/periodo_ini"];
              const periodo_fin = store.getters["mapa/periodo_fin"];

              // PERIODO

              var res_periodoini = await ApiIndicador.getPeriodoBySigla(
                periodo_ini,
                idindicador.value
              );
              var res_periodofin = await ApiIndicador.getPeriodoBySigla(
                periodo_fin,
                idindicador.value
              );

              var periodoini = res_periodoini.data[0]["idperiodoevaluacion"];
              var periodofin = res_periodofin.data[0]["idperiodoevaluacion"];

              // END PERIODO

              LocalgetAllByPeriodo();

              ApiIndicador.getIndicadorIndicador(
                abreviatura,
                toRaw(sigla.value),
                provincia,
                periodo_ini,
                periodo_fin,
                quintil,
                periodoini,
                periodofin
              )
                .then((response) => {
                  console.log(response);
                  const conPorcentajes = response.data.map((p) => {
                    const porcentaje = calculaPorcentaje(p.total_numerador, p.total_denominador);

                    const porcentajeHex = semaforoMapa(
                      coloresSemaforoMapa,
                      p.total_denominador,
                      porcentaje
                    );

                    return {
                      ...p,
                      porcentaje,
                      porcentajeHex
                    };
                  });

                  data.value = conPorcentajes;

                  mapbox.value = {
                    abreviatura: abreviatura,
                    idcoordenada: idcoordenada,
                    idubigeo: idubigeo,
                    denominacion: denominacionParse,
                    total_numerador_distrito:
                      response.data[response.data.length - 1].total_numerador_distrito,
                    total_denominador_distrito:
                      response.data[response.data.length - 1].total_denominador_distrito,
                    lat: denominacion.geometry.coordinates[0][0][0] + "",
                    lng: denominacion.geometry.coordinates[0][0][1] + "",
                    data_nombres: [],
                    zoom: 9,
                    indicador: idindicador.value
                  };

                  if (response.data.length > 0) {
                    porcentaje.value = calculaPorcentaje(
                      response.data[response.data.length - 1].total_numerador_distrito,
                      response.data[response.data.length - 1].total_denominador_distrito
                    );

                    porcentajeColor.value = semaforoByPorcentajeIndicador(
                      coloresSemaforoMapa.RANGO_SINDATO,
                      coloresSemaforoMapa.RANGO_BAJO,
                      coloresSemaforoMapa.RANGO_MEDIO,
                      porcentaje.value
                    );

                    var con_numerador = 0;
                    var con_denominador = 0;

                    response.data.forEach((x) => {
                      con_numerador += parseInt(x["total_numerador"]);
                      con_denominador += parseInt(x["total_denominador"]);
                    });

                    numerador.value = con_numerador;
                    denominador.value = con_denominador;
                  } else {
                    porcentaje.value = 0;
                    numerador.value = 0;
                    denominador.value = 0;
                  }

                  const distrito = [];
                  const total_denominador_distrito = [];
                  const total_numerador_distrito = [];
                  const porcentaje_distrito = [];

                  response.data.forEach((element) => {
                    distrito.push(element.distrito);
                    total_numerador_distrito.push(element.total_numerador);
                    total_denominador_distrito.push(element.total_denominador);
                    porcentaje_distrito.push(
                      calculaPorcentaje(element.total_numerador, element.total_denominador)
                    );
                  });

                  grafica.barRainfall.options = optionsBar(
                    distrito,
                    total_numerador_distrito,
                    total_denominador_distrito,
                    porcentaje_distrito
                  );

                  grafica.gauge.options = optionsGauge(
                    toRaw(porcentaje.value),
                    toRaw(porcentajeColor.value)
                  );
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        }
      }
    };

    const LocalgetAllByPeriodo = async () => {
      const provincia = store.getters["mapa/provincia"];
      const distrito = store.getters["mapa/distrito"];
      const periodo_ini = store.getters["mapa/periodo_ini"];
      const periodo_fin = store.getters["mapa/periodo_fin"];

      // PERIODO

      var res_periodoini = await ApiIndicador.getPeriodoBySigla(periodo_ini, idindicador.value);
      var res_periodofin = await ApiIndicador.getPeriodoBySigla(periodo_fin, idindicador.value);

      var periodoini = res_periodoini.data[0]["idperiodoevaluacion"];
      var periodofin = res_periodofin.data[0]["idperiodoevaluacion"];

      // END PERIODO

      ApiIndicador.getAllByPeriodo(
        sigla.value,
        provincia,
        distrito,
        periodo_ini,
        periodo_fin,
        periodoini,
        periodofin
      )
        .then((response) => {
          const months = [];
          const porcentaje = [];
          const total_numerador_periodo = [];
          const total_denominador_periodo = [];

          response.data.forEach((element) => {
            months.push(formateaIdsPeriodoHuman(element.idperiodo));

            total_numerador_periodo.push(element.total_numerador_periodo);
            total_denominador_periodo.push(element.total_denominador_periodo);
            porcentaje.push(
              calculaPorcentaje(element.total_numerador_periodo, element.total_denominador_periodo)
            );
          });

          grafica.line.options = optionsLine(months, porcentaje);
        })
        .catch((err) => console.log(err));
    };

    const goBack = () => router.back();

    const fetchFooterFuente = () => {
      ApiIndicador.getFooterFuente(idindicador.value)
        .then((res) => {
          fechaIngresoTabla.value = res.data[0]?.LABEL_FUENTE || "";
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return {
      changedistrito,
      indicadorData,
      porcentaje,
      numerador,
      denominador,
      isLoading,
      porcentajeColor,
      mapbox,
      data,
      calculo,
      denominacion,
      escalaColores,
      fichatecnica,
      titulodenominado,
      grafica,
      columnsIndicadorDistrito,
      changePeriodo,
      titulonumerado,
      abreviatura,
      coloresSemaforo,
      data_denominador,
      goBack,
      graphics,
      calculaPorcentaje,
      height,
      fechaIngresoTabla,
      esIndicadorMC,
      paquetesIntegrados,
      optionsGrafico,
      escalaColoresPaquete,
      optionsGraficoValor
    };
  }
};
</script>

<style scoped></style>
