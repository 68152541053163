<template>
  <a-form :model="formState" class="flex flex-col md:flex-row md:gap-5">
    <a-form-item label="PERIODO INICIO" name="idperiodo_ini" class="my-1 inline-block md:w-52">
      <a-select v-model:value="formState.idperiodo_ini" @select="onSelectPeriodoIni">
        <a-select-option
          v-for="periodo in periodos"
          :value="periodo.idperiodo"
          :key="periodo.idperiodo"
        >
          {{ periodo.denominacion }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="PERIODO FIN" name="idperiodo_fin" class="my-1 inline-block md:w-52">
      <a-select v-model:value="formState.idperiodo_fin" @select="onSelectPeriodoFin">
        <a-select-option
          v-for="periodo in periodos"
          :value="periodo.idperiodo"
          :key="periodo.idperiodo"
        >
          {{ periodo.denominacion }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="PROVINCIA" name="provincia" class="my-1 inline-block md:w-52">
      <a-select v-model:value="formState.provincia" @select="onSelectProvincia">
        <a-select-option key="ALL" value="">TODAS</a-select-option>
        <a-select-option v-for="p in provincias" :value="p.provincia" :key="p.provincia">
          {{ p.provincia }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="DISTRITO" name="distrito" class="my-1 inline-block md:w-52">
      <a-select v-model:value="formState.distrito" @select="onSelectDistrito">
        <a-select-option key="ALL" value="">TODOS</a-select-option>
        <a-select-option v-for="d in distritos" :value="d.idubigeoinei" :key="d.idubigeoinei">
          {{ d.distrito }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <div id="quintil" style="display: none">
      <a-form-item label="QUINTIL 1 Y 2" name="quintil" class="my-1 inline-block md:w-52">
        <a-select v-model:value="formState.quintil" @select="onSelectQuintil">
          <a-select-option key="" value="">TODOS</a-select-option>
          <a-select-option value="1">SI</a-select-option>
          <a-select-option value="0">NO</a-select-option>
        </a-select>
      </a-form-item>
    </div>
  </a-form>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import IndicadorApi from "@/api/indicador";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  emits: ["changedistrito", "changePeriodo", "changeFechaDenominacion"],
  setup(props, { emit }) {
    const route = useRoute();
    const store = useStore();
    const periodos = ref([]);
    const provincias = ref([]);
    const distritos = ref([]);

    const idindicador = route.params?.indicador;

    const formState = reactive({
      idperiodo_ini: "",
      idperiodo_fin: "",
      provincia: "",
      distrito: "",
      quintil: "1"
    });

    const fetchPeriodos = () => {
      IndicadorApi.getperiodoByByIDIndicador(idindicador)
        .then((response) => {
          periodos.value = response.data;

          var fechaDenominacion = response.data[0].denominacion;
          emit("changeFechaDenominacion", fechaDenominacion);

          formState.idperiodo_ini = response.data[0]["idperiodo"];
          formState.idperiodo_fin = response.data[0]["idperiodo"];

          store.dispatch("mapa/setPeriodoIni", formState.idperiodo_ini);
          store.dispatch("mapa/setPeriodoFin", formState.idperiodo_fin);
        })
        .catch((err) => console.log(err));
    };

    const fetchProvincias = () => {
      IndicadorApi.getProvincias()
        .then((response) => {
          provincias.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    const fetchDistritos = (provincia = "") => {
      IndicadorApi.getDistritos(provincia)
        .then((response) => {
          distritos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      const anio = new Date().getFullYear();
      const month = new Date().getMonth();
      const month_ini = month - 2 < 10 ? "" + 0 + (month - 2) : month - 2;
      const month_fin = month - 1 < 10 ? "" + 0 + (month - 1) : month - 1;

      formState.idperiodo_ini = "" + anio + month_ini;
      formState.idperiodo_fin = "" + anio + month_fin;

      store.dispatch("mapa/setPeriodoIni", formState.idperiodo_ini);
      store.dispatch("mapa/setPeriodoFin", formState.idperiodo_fin);

      if (idindicador == 14 || idindicador == 15) {
        document.getElementById("quintil").style.display = "block";
        formState.quintil = "1";
        emit("changePeriodo");
      }

      fetchPeriodos();
      fetchProvincias();
      fetchDistritos();
    });

    const onSelectProvincia = (provincia) => {
      store.dispatch("mapa/setProvincia", provincia);
      store.dispatch("mapa/setDistrito", "");
      store.dispatch("mapa/setUbigeo", "");

      formState.distrito = "";

      fetchDistritos(provincia);
      emit("changePeriodo");
    };

    const onSelectQuintil = (quintil) => {
      store.dispatch("mapa/setQuintil", quintil);
      emit("changePeriodo");
    };

    const onSelectPeriodoIni = (periodo) => {
      store.dispatch("mapa/setPeriodoIni", periodo);
      emit("changePeriodo");
    };

    const onSelectPeriodoFin = (periodo) => {
      store.dispatch("mapa/setPeriodoFin", periodo);
      emit("changePeriodo");
    };

    const onSelectPeriodo = (periodo) => {
      store.dispatch("mapa/setPeriodo", periodo);
      emit("changePeriodo");
    };

    const onSelectDistrito = (ubigeo) => {
      store.dispatch("mapa/setUbigeo", ubigeo);
      emit("changePeriodo");
      emit("changedistrito");
    };

    return {
      formState,
      periodos,
      provincias,
      distritos,
      onSelectPeriodo,
      onSelectPeriodoIni,
      onSelectPeriodoFin,
      onSelectProvincia,
      onSelectDistrito,
      onSelectQuintil
    };
  }
};
</script>

<style scoped></style>
