<template>
  <div class="text-center">
    <img
      :class="'inline ' + sizeIcon"
      :src="require('@/assets/icon/' + icon + '.png')"
      :alt="icon"
    />
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    icon: {
      type: String,
      default: "new-ficha",
      required: true
    },
    size: {
      type: String,
      default: "md"
    }
  },
  setup(props) {
    const sizeAvailable = {
      xs: "w-5",
      sm: "w-6",
      md: "w-8",
      lg: "w-12"
    };

    const sizeIcon = computed(() => sizeAvailable[props.size] || "md");

    return {
      sizeIcon
    };
  }
};
</script>

<style></style>
