export const columnsIndicadorDistrito = [
  {
    title: "N°",
    key: "index",
    width: 30,
    slots: { customRender: "rangepages" }
  },
  {
    title: "PROVINCIA",
    dataIndex: "provincia",
    key: "provincia",
    sorter: (a, b) => a.provincia.localeCompare(b.provincia),
    sortDirections: ["descend", "ascend"],
    width: 120
  },
  {
    title: "DISTRITO",
    dataIndex: "distrito",
    key: "distrito",
    sorter: (a, b) => a.distrito.localeCompare(b.distrito),
    sortDirections: ["descend", "ascend"],
    width: 180
  },
  {
    title: "Avance",
    dataIndex: "porcentaje",
    key: "porcentaje",
    width: 100,
    sorter: (a, b) => b.porcentaje - a.porcentaje,
    defaultSortOrder: "ascend",
    slots: { customRender: "porcentaje" }
  },
  {
    title: "NUM/DEN",
    key: "numeradordenominador",
    width: 50,
    slots: { customRender: "numeradordenominador" }
  }
];
