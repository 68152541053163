<template>
  <div class="flex gap-2 mb-4 md:gap-3 lg:gap-4">
    <span v-if="isLoading" class="w-full bg-green-200">
      <a-skeleton :paragraph="{ rows: 1 }" active />
    </span>
    <span v-if="!isLoading" class="w-3/12 md:w-1/12 flex items-center justify-center"
      ><span
        class="flex justify-center items-center font-semibold text-lg lg:text-xl text-white border-4 border-white w-24 h-24 rounded-full"
        :style="{ background: indicadorBackground }"
      >
        <p class="strokeme">{{ porcentaje }}%</p>
      </span></span
    >
    <div v-if="!isLoading" class="w-9/12 md:w-11/12 border bg-white">
      <p class="text-black">
        <span
          class="py-2 px-6 text-white border font-semibold strokeme"
          :style="{ background: indicadorBackground }"
          >Indicador: {{ abreviatura }}
        </span>
      </p>
      <p class="px-4 py-2 lg:text-lg lg:px-4 lg:pt-4 font-semibold">
        {{ denominacion }}
      </p>
      <button v-if="sigla" class="text-sm" @click="handleReporteIndicador">
        <span class="flex items-center gap-1 m-1 pl-3 text-xs">
          <i>Descargar ficha técnica:</i><IconGeneral icon="see-pdf" size="xs" />PDF</span
        >
      </button>
    </div>
  </div>

  <div class="flex flex-col gap-8 lg:flex-row w-full">
    <div class="flex w-full lg:w-1/2 gap-2 mb-4 md:gap-3 lg:gap-5">
      <span v-if="isLoading" class="w-full bg-yellow-200">
        <a-skeleton :paragraph="{ rows: 1 }" active />
      </span>

      <span
        v-if="!isLoading"
        class="w-3/12 md:w-2/12 flex items-center justify-center bg-yellow-200 font-semibold text-lg lg:text-2xl py-8 border"
        >{{ numerador }}</span
      >
      <span class="w-9/12 md:w-10/12 bg-yellow-200 border">
        <p class="text-black">
          <span class="bg-yellow-400 py-2 px-4 font-semibold text-xs border">AVANCE </span>
        </p>
        <p v-if="!isLoading" class="flex items-center px-4 py-2 font-semibold lg:text-sm lg:p-4">
          {{ titulonumerado }}
        </p>
      </span>
    </div>

    <div class="flex w-full lg:w-1/2 gap-2 mb-4 md:gap-3 lg:gap-5">
      <span v-if="isLoading" class="w-full bg-blue-200">
        <a-skeleton :paragraph="{ rows: 1 }" active />
      </span>

      <span
        v-if="!isLoading"
        class="w-3/12 md:w-2/12 flex items-center justify-center bg-blue-200 font-semibold text-lg lg:text-2xl py-8 border"
      >
        {{ denominador }}
      </span>

      <span class="w-9/12 md:w-10/12 bg-blue-200 border">
        <p class="text-black">
          <span class="bg-blue-400 py-2 px-4 font-semibold text-xs border">PADRÓN </span>
        </p>
        <p v-if="!isLoading" class="flex items-center px-4 py-2 font-semibold lg:text-sm lg:p-4">
          {{ titulodenominado }}
        </p>
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import IconGeneral from "@/components/IconGeneral.vue";

export default {
  components: {
    IconGeneral
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    porcentaje: {
      type: String,
      default: ""
    },
    abreviatura: {
      type: String,
      default: ""
    },
    denominacion: {
      type: String,
      default: ""
    },
    numerador: {
      type: String,
      default: ""
    },
    titulonumerado: {
      type: String,
      default: ""
    },
    denominador: {
      type: String,
      default: ""
    },
    titulodenominado: {
      type: String,
      default: ""
    },
    porcentajeColor: {
      type: String,
      default: ""
    },
    sigla: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const handleReporteIndicador = () => {
      window.open(`${process.env.VUE_APP_BASE_API}/FICHAS/${props.sigla}.pdf`, "_blank");
    };
    const indicadorBackground = computed(() => props.porcentajeColor);

    return {
      indicadorBackground,
      handleReporteIndicador
    };
  }
};
</script>

<style lang="less" scoped>
.strokeme {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
</style>
